<template>
    <div class="print">
        <p class="bold center">
            ООО «Клиника Профессора»<br/>
            ИНН 1435316805, КПП 143501001<br/>
            Адрес: 677010, г. Якутск, ул. Радужная, 69/12<br/>
            Телефон: +7 (914)2776990; электронный адрес: klinika.professora@mail.ru
        </p>
        
        <hr size=2 width="100%" align=center>
        <p class="bold center">
            Осмотр {{ visit.doctor_position.length>0 ? toCases(visit.doctor_position, 'р').toLowerCase() : 'травматолога-ортопеда' }}
        </p>
        <p style="text-align:left;font-weight:bold;">
            {{ visit.visitDate.split(' ')[0] }} г.
        </p>
        <p style="text-align:justify">
            <b>ФИО пациента:</b> {{ visit.patient_full_name }}<br/>
            <b>Возраст:</b> {{ patientAge }}<br/>
            <b>Рост:</b> {{ visit.patient_height>0 ? visit.patient_height + " см" : "не указан" }}, <b>вес:</b> {{ visit.patient_weight>0 ? visit.patient_weight + " кг" : "не указан" }}
        </p>
        <div style="text-align:justify" v-if="amb">
            <p style="text-align:justify">
                <b>Жалобы:</b> {{ visit.compliant }}
            </p>
            <p style="text-align:justify">
                <b>Анамнез болезни:</b> {{ visit.anamnes_med }}
            </p>
            <p style="text-align:justify" v-if="visit.anamnes_life.length > 0">
                <b>Анамнез жизни:</b> {{ visit.anamnes_life }}
            </p>
            <p style="text-align:justify">
                <b>Аллергические реакции:</b> {{ visit.status_alergo }}
            </p>
            <p style="text-align:justify">
                <b>Объективный статус:</b> {{ visit.status_objective }}
            </p>
            <p style="text-align:justify">
                <b>Локальный статус:</b> {{ visit.status_local }}
            </p>
            <p style="text-align:justify" v-if="visit.manipulation.length > 0">
                <b>Сделано:</b> {{ visit.manipulation.length > 0 ? visit.manipulation : 'Нет' }}
            </p>
            <p style="text-align:justify">
                <b>Данные обследований:</b> {{ visit.exam_data }}
            </p>
            <p style="text-align:justify">
                <b>Клинический диагноз:</b> {{ visit.diagnosis }}
            </p>
            <p style="text-align:justify">
                <b>Код диагноза:</b> {{ visit.mkb10 }}
            </p>
            <p style="text-align:justify">
                <b>Рекомендации:</b><br/> {{ visit.recom }}
            </p>
        </div>


        <div style="text-align:justify" v-if="hos">
            <p style="text-align:justify">
                <b>Жалобы:</b> {{ visit.compliant }}
            </p>
            <p style="text-align:justify">
                <b>Анамнез болезни:</b> {{ visit.anamnes_med }}
            </p>
            <p style="text-align:justify">
                <b>Анамнез жизни:</b> {{ visit.anamnes_life }}
            </p>
            <p style="text-align:justify">
                <b>Аллергические реакции:</b> {{ visit.status_alergo }}
            </p>
            <p style="text-align:justify">
                <b>Объективный статус:</b> {{ visit.status_objective }}
            </p>
            <p style="text-align:justify">
                <b>Локальный статус:</b> {{ visit.status_local }}
            </p>
            <p style="text-align:justify">
                <b>Клинический минимум:</b> {{ visit.clinimum }}
            </p>
            <p style="text-align:justify">
                <b>Данные обследований:</b> {{ visit.exam_data }}
            </p>
            <p style="text-align:justify">
                <b>Обоснование диагноза:</b> {{ visit.proof }}
            </p>
            <p style="text-align:justify">
                <b>Клинический диагноз:</b>
                Основной: {{ visit.diagnosis }}
                Сопутствующий: {{ visit.diagnosis_secondary }}
            </p>
            <p style="text-align:justify">
                <b>>Код диагноза:</b>
                Основной: {{ visit.mkb10 }}
            </p>
            <p style="text-align:justify">
                <b>План ведения и лечения:</b> {{ visit.cure_plan }}
            </p>
        </div>
        
        <p style="text-align:justify">
            Врач: {{ visit.doctor_full_name}}
        </p>
        <div class="pagebreak" />
    </div>
</template>
<script setup>
    import { formatDate, toCases } from '@/common/rcfx.js'
</script>
<script>
    export default {
        props: ['visit'],
        computed: {
            amb() {
            return this.visit.depType === "0"
            },
            hos() {
                return this.visit.depType === "1"
            },
            patientAge() {
                if (!this.visit.patient_birthdate) { 
                    return 'Не указан' 
                }else{
                    var birthdate = new Date(this.visit.patient_birthdate.split('.').reverse().join('-'))
                    var today = new Date()
                    return Math.floor((today-birthdate)/3600000/24/365) + " (" + this.visit.patient_birthdate.split('-').reverse().join('.') + " г.р.)"
                }
            }
        }
    }
    </script>