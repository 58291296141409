import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyBk6hITyxIVOJo6LcBpuagp3y9L27IVoBE",
  authDomain: "reactiv-clinic.firebaseapp.com",
  projectId: "reactiv-clinic",
  storageBucket: "reactiv-clinic.firebasestorage.app",
  messagingSenderId: "761744494567",
  appId: "1:761744494567:web:adbcaed97705ad27cc3742"
};

if (firebase.messaging.isSupported()) {
  console.log('fcm supported')
  firebase.initializeApp(firebaseConfig)
  var defexport = firebase.messaging()
}
// }else{ alert('fcm not supported')}

// export default firebase.messaging()
export default defexport