export function allowedDays(date) {
    var currentTS = Date.now()
    var oneDay = 60*60*24
    var today = (Math.floor( Math.floor(currentTS/1000) / oneDay ) * oneDay - oneDay) * 1000
    var delta = oneDay*14
    var endTS = currentTS + delta*1000

    if (date.getTime() >= today && date.getTime() <= endTS) {
      return true 
    }else{ 
      return false 
    }
}
export const asd = function(date) {
    return date
}
export function ruDateTime(dt) {
    var day, month, year, hours, minutes, seconds;
    var d = dt.split(' ')[0]
    var t = dt.split(' ')[1]
    var result = d.match("[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}");
    if(null != result) {
        var dateSplitted = result[0].split(result[1]);
        day = dateSplitted[0];
        month = dateSplitted[1];
        year = dateSplitted[2];
    }
    var result = d.match("[0-9]{4}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{2}");
    if(null != result) {
        var dateSplitted = result[0].split(result[1]);
        day = dateSplitted[2];
        month = dateSplitted[1];
        year = dateSplitted[0];
    }
    var result = t.match("[0-9]{2}([\-/ \:])[0-9]{2}[\-/ \:][0-9]{2}");
    if(null != result) {
        var timeSplitted = result[0].split(result[1]);
        //console.log("res is " + result)
        hours = timeSplitted[0];
        minutes = timeSplitted[1];
        seconds = timeSplitted[2];
    }

    if(month>12) {
        aux = day;
        day = month;
        month = aux;
    }
    var obj = {}
    obj.date = day+"/"+month+"/"+year
    obj.time = hours+":"+minutes
    return obj
}
// formatDate: принимает первым параметром объект Date, вторым параметром формат вывода en|ru
// возвращает String в виде Даты
export function formatDate(dateObject, format) {
    var d = dateObject
    switch (format) {
        case 'us':
            //return date.toLocaleDateString('en-GB', options);
            return String(String(d.getFullYear() + '-'+String(d.getMonth()+1).padStart(2, '0') + '-'+String(d.getDate()).padStart(2, '0')))
        case 'ru':
            return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0') + '.'+String(d.getFullYear())
        case 'short':
            return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0')
    }
}
// formatDate: принимает первым параметром объект Date, вторым параметром формат вывода en|ru
// возвращает String в виде Времени 00:00:00
export function formatTime(dateObject) {
    var d = dateObject
        return String(d.getHours()).padStart(2, '0') + ':' + String(d.getMinutes()).padStart(2, '0') + ':' + String(d.getSeconds()).padStart(2, '0')
}
export function formatDateTime(dateObject, format) {
    return formatDate(dateObject, format) + ' ' + formatTime(dateObject)
}
export function dayString(digit) {
        if (digit == 1){
            return 'день'
        }
        if (digit > 1 && digit < 5) {
            return 'дня'
        }
        if (digit > 4 && digit < 21) {
            return 'дней'
        }
}
Date.prototype.ruString = function(format) {

    switch (format) {
      case 'us':
        return String(String(this.getFullYear() + '-'+String(this.getMonth()+1).padStart(2, '0') + '-'+String(this.getDate()).padStart(2, '0')))
      case 'ru':
        return String(d.getDate()).padStart(2, '0') + '.'+String(d.getMonth()+1).padStart(2, '0') + '.'+String(d.getFullYear())
      case 'orm':
        return String(
          String(this.getFullYear()) + '-'+ 
           String(this.getMonth()+1).padStart(2, '0') + '-' + 
           String(this.getDate()).padStart(2, '0') + ' ' + 
          String(this.getHours()).padStart(2, '0') + '-' + 
          String(this.getMinutes()+1).padStart(2, '0') + '-'+ 
          String(this.getSeconds()).padStart(2, '0')
          )
    }
}
export function detectOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }
export async function getNotificationPermission() {
    const state = await Notification.requestPermission(); 
    if (state !== 'granted') {
        return false
        }
        return true
    }
export function toCase(str, choice) {
  var strPub = {
      // правила для окончаний
      а: ["ы", "е", "у", "ой", "е"],
      "(ш/ж/к/ч)а": ["%и", "%е", "%у", "%ой", "%е"],
      "б/в/м/г/д/л/ж/з/к/н/п/т/ф/ч/ц/щ/р/х": ["%а", "%у", "%а", "%ом", "%е"],
      и: ["ей", "ям", "%", "ями", "ях"],
      ая: ["ой", "ой", "%", "ой", "ой"],
      ый: ["ого", "ому", "%", "ым", "ом"],
      й: ["я", "ю", "я", "ем", "е"],
      о: ["а", "у", "%", "ом", "е"],
      "с/ш": ["%а", "%у", "%", "%ом", "%е"],
      ы: ["ов", "ам", "%", "ами", "ах"],
      ь: ["я", "ю", "я", "ем", "е"],
      уль: ["ули", "уле", "улю", "улей", "уле"],
      "(ч/ш/д/т)ь": ["%и", "%и", "%ь", "%ью", "%и"],
      я: ["и", "е", "ю", "ей", "е"]
    },
    cases = {
      // номера для падежей, не считая Именительный
      р: 0,
      д: 1,
      в: 2,
      т: 3,
      п: 4
    },
    exs = {
      // исключения, сколько символов забирать с конца
      ц: 2,
      ок: 2
    },
    lastIndex,
    reformedStr,
    forLong,
    splitted,
    groupped,
    forPseudo;
  for (var i in strPub) {
    if (i.length > 1 && str.slice(-i.length) == i) {
      // для окончаний, длиной >1
      lastIndex = i;
      reformedStr = str.slice(0, -lastIndex.length);
      break;
    } else if (/[\(\)]+/g.test(i)) {
      // фича: группировка окончаний
      i.replace(/\(([^\(\)]+)\)([^\(\)]+)?/g, function(a, b, c) {
        splitted = b.split("/");
        for (var o = 0; o < splitted.length; o++) {
          groupped = splitted[o] + c;
          strPub[groupped] = strPub[i];
          if (str.slice(-groupped.length) == groupped) {
            for (
              var x = 0, eachSplited = strPub[groupped];
              x < eachSplited.length;
              x++
            ) {
              eachSplited[x] = eachSplited[x].replace("%", splitted[o]);
            }
            reformedStr = str.slice(0, -groupped.length);
            forPseudo = groupped;
          }
        }
      });
    } else {
      // дефолт
      lastIndex = str.slice(-1);
      reformedStr = str.slice(0, -(forPseudo || lastIndex).length);
    }
    if (/\//.test(i) && !/[\(\)]+/g.test(i) && new RegExp(lastIndex).test(i))
      forLong = i; // группированные окончания, разделающиеся слешем
    for (var o in exs) {
      // поиск исключений
      if (str.slice(-o.length) == o) reformedStr = str.slice(0, -exs[o]);
    }
  }
  return (
    reformedStr +
    strPub[forPseudo || forLong || lastIndex][cases[choice]].replace(
      "%",
      lastIndex
    )
  );
};
export function toCases(str, pagej) {
  var result
  var phrase = str.split('-')
  if (phrase.length>1) {
    result = []
    for (var i=0;i<phrase.length;i++) {
      result.push(toCase(phrase[i], pagej))
    }
    result = result.join('-')
  }else{
    result = toCase(str, pagej)
  }
  return result
}